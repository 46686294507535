import { ApolloManager } from 'src/helpers/ApolloManager';
import { CouponCode } from 'src/helpers/CouponCodeManager/CouponCode';
import getActiveCouponCodes from './getActiveCouponCodes.graphql';
import updateCouponCode from './updateCouponCode.graphql';
import { RegistrationManager } from '../RegistrationManager';

interface Result {
	success: boolean;
	error?: string;
}

interface CouponCodeUpdateData {
	id: string;
	code?: string;
	discount?: number;
	description?: string;
	active?: boolean;
	limitedUses?: boolean;
	remainingUses?: number;
}

interface CouponCodeFetchResult {
	allCouponCodes: CouponCode[];
}

class CouponCodeImplementation {
	public readonly getActiveCouponCodes = async (): Promise<CouponCode[]> => {
		try {
			const result = await ApolloManager.client.query<CouponCodeFetchResult>({
				query: getActiveCouponCodes,
			});
			if (result.data.allCouponCodes) {
				return result.data.allCouponCodes;
			} else {
				return [];
			}
		} catch (error) {
			console.error(`An error occurred fetching active coupon codes: Error was ${error}`);
		}
		return [];
	};

	public readonly updateCouponCode = async (
		couponCodeData: CouponCodeUpdateData
	): Promise<Result> => {
		let success: boolean = false;
		try {
			const result = await ApolloManager.client.mutate<CouponCodeUpdateData>({
				mutation: updateCouponCode,
				variables: couponCodeData,
			});
			success = result ? true : false;
		} catch (error) {
			console.error(`Coupon code update failed.`);
		}
		return {
			success: success,
			error: !success ? `Failed to update coupon code.` : undefined,
		};
	};

	public readonly useCouponCode = async (couponCodeData: CouponCodeUpdateData): Promise<Result> => {
		let variables = { ...couponCodeData };
		if (variables.remainingUses) {
			if (variables.remainingUses > 0) {
				variables.remainingUses -= 1;
			}
			return await this.updateCouponCode(variables);
		}
		return {
			success: false,
			error: `Failed to use coupon code.`,
		};
	};

	public readonly getActiveCouponCodesForUser = async (
		userId: number
	): Promise<CouponCode[] | undefined> => {
		const registrations = await RegistrationManager.getRegistrationsForUser(userId);
		return registrations
			?.slice()
			.sort((a, b) => (b?.id || 0) - (a?.id || 0))
			.map((registration) => registration.couponCode)
			.filter(
				(couponCode): couponCode is CouponCode =>
					!!couponCode?.active && (couponCode.limitedUses ? couponCode.remainingUses > 0 : true)
			);
	};

	public readonly getMostRecentFreeCouponCodeForUser = async (
		userId?: number
	): Promise<CouponCode | undefined> => {
		if (!userId) return undefined;
		const activeCouponCodes = await this.getActiveCouponCodesForUser(userId);
		return activeCouponCodes?.find((couponCode) => couponCode.discount == 1);
	};
}

export const CouponCodeManager = new CouponCodeImplementation();
