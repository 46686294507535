import { ApolloManager } from 'src/helpers/ApolloManager';
import { KeystoneUser } from '../UserManager';
import getActiveProducts from './getActiveProducts.graphql';
import { SettingsManager } from 'src/helpers/SettingsManager';

export interface Product {
	id: number;
	name: string;
	price: number;
	currencyCode?: string;
	active?: boolean;
}

interface DatabaseProduct {
	id: number;
	name: string;
	prices: string;
	active: boolean;
}

class ProductImplementation {
	public readonly getActiveProductsForUser = async (
		user: KeystoneUser
	): Promise<Product[] | undefined> => {
		try {
			const dbResult = await ApolloManager.client.query<{ allProducts: DatabaseProduct[] }>({
				query: getActiveProducts,
				fetchPolicy: 'no-cache',
			});
			if (dbResult.data && dbResult.data.allProducts && dbResult.data.allProducts.length > 0) {
				const countries: any[] = (await SettingsManager.getCountryList()) || [];
				// Use user to determine locale to reduce prices to price for each product
				const result: Product[] = dbResult.data.allProducts.map((product) => {
					const newProduct: Product = {
						...{ ...product, prices: undefined, currencyCode: '' },
						price: 0,
					};
					const pricesDict = JSON.parse(product.prices);
					let foundPrice: any;
					let defaultPrice: any;
					let userCountry: any;
					countries.forEach((country: any) => {
						if (country.caption == user.country) userCountry = country;
					});
					pricesDict.map((price: { countryCode: string; price: string; default?: boolean }) => {
						if (price.default == true) defaultPrice = price;
						if (
							price.countryCode == userCountry?.twoLetterCode ||
							price.countryCode == userCountry?.threeLetterCode
						)
							foundPrice = price;
					});
					if (foundPrice) {
						newProduct.price = Number(foundPrice.price);
						newProduct.currencyCode = foundPrice.currencyCode;
					} else if (defaultPrice) {
						newProduct.price = Number(defaultPrice.price);
						newProduct.currencyCode = defaultPrice.currencyCode;
					} else {
						newProduct.price = 0;
						newProduct.currencyCode = 'N/A';
					}
					return newProduct;
				});
				return result;
			}
		} catch (error) {
			console.error(`Product data retrieval failed. ${error}`);
		}
		return undefined;
	};
}
[];

export const ProductManager = new ProductImplementation();
